/* Design for desktop/laptop (default) - start */
.pageWrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.welcomeImageContainer {
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1150px;
    padding-top: 25px;
    height: calc(100vh - 115px);
    min-height: 964px;
}

.welcomeImage {
    position: absolute;
    z-index: 0;
    width: 100%;
    max-width: 1150px;
    height: calc(100vh - 115px);
    min-height: 964px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 100%;
    max-width: 770px;
    margin: 0 auto;
    position: relative;
}

.title {
    color: #191717;
    text-align: center;
    font-family: Nohemi_Bold;
    font-size: 57px;
    line-height: 120%;
    margin: 0px;
    margin-bottom: 18px;
    margin-top: 113px;
}

.headTitle {
    color: #191717;
    text-align: center;
    font-family: Nohemi_Bold;
    font-size: 32px;
    line-height: 130%;
    margin: 0;
}


.textWithUnderline {
    display: inline-block;
    position: relative;
    text-align: center;
}

.description {
    color: #403C3A;
    text-align: center;
    font-family: Geist_Regular;
    font-size: 22px;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 32px;
}

.welcomeFace {
    width: 100%;
    max-width: 353px;
}

.nextBtnContainer {
    text-decoration: none;
}

.infoButton {
    position: absolute;
    left: 62px;
    bottom: 50px;
}

.video {
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
    max-height: 500px;
}

.modalRoot {
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(17px);
}

.modalContainer {
    max-width: unset !important;
    max-height: unset !important;
    padding: 0px !important;
    border-radius: 23px !important;
    background-color: #FBFBF9 !important;
    box-shadow: 1.426px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, -1.426px 0px 0px 0px rgba(0, 0, 0, 0.17) inset, 0px -1.426px 0px 0px rgba(0, 0, 0, 0.17) inset, 0px 1.426px 0px 0px rgba(204, 204, 204, 0.50) inset, 0px 28.519px 28.519px -11.408px rgba(26, 26, 26, 0.28) !important;
}

.modalContentContainer {
    background: #FBFBF9;
    padding: 34px 28px;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.modalHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #191717;
    font-family: Nohemi_Regular;
    font-size: 23px;
    line-height: 125%;
    padding: 23px;
    background-color: #F5F5EF;
    border-bottom: 1px solid #E6E5D1;
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.footerContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
/* Design for desktop/laptop (default) - end */

/* Design for mobile/small tablet - start */
@media only screen and (max-width: 1000px) {
    .pageWrapper {
        margin-top: 77px;
    }

    .welcomeImageContainer {
        position: relative;
        top: unset;
        height: auto;
        min-height: unset;
        width: auto;
        padding: 32px 16px;
    }

    .contentContainer {
        gap: 32px;
    }

    .textContainer {
        gap: 12px;
    }

    .welcomeImage {
        display: none;
    }

    .welcomeFace {
        max-width: 163px;
    }

    .title {
        font-size: 30px;
        margin-top: 0px;
        margin-bottom: -20px;
    }

    .headTitle {
        font-size: 20px;
    }

    .description {
        font-size: 14px;
        margin-bottom: 0px;
    }

    .nextBtnContainer {
        width: 100%;
    }

    .infoButton {
        position: relative;
        left: unset;
        bottom: unset;
        margin: 0 auto;
        margin-top: 16px;
        max-width: 764px;
    }

    .footerContainer {
        width: unset;
    }

    .modalHeaderContainer {
        font-size: 16px;
        padding: 16px;
    }

    .modalContentContainer {
        padding: 20px 16px;
    }
}
/* Design for mobile/small tablet - end */

/* Design for screens with big height (yoboo tablet) - start */
@media only screen and (min-height: 1600px) {
    .welcomeImageContainer {
        max-width: 938px;
    }

    .contentContainer {
        gap: 87px;
    }

    .title {
        font-size: 72px;
        margin-bottom: 27px;
        margin-top: 150px;
    }

    .description {
        margin-bottom: 98px;
    }

    .welcomeFace {
        max-width: 533px;
    }
}
/* Design for screens with big height (yoboo tablet) - end */
