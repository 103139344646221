.error_container {
    padding: 0 2rem;
}

.containerError {
    padding: 0px 64px;
    overflow: hidden;
}

.content {
    padding: 32px 64px;
    border-radius: 32px;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
}

.results_error_box {
    display: flex;
    max-width: 480px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px;
    background: #FBFBF9;
    box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.13) inset, -1px 0px 0px 0px rgba(0, 0, 0, 0.17) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.17) inset, 0px 1px 0px 0px rgba(204, 204, 204, 0.50) inset, 0px 20px 20px -8px rgba(26, 26, 26, 0.28);
}

.results_error_header {
    border: 1.5px solid rgba(26, 26, 26, 0.15);
    border-bottom: 1px solid #E6E5D1;
    background: #F5F5EF;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.results_error_body {
    display: flex;
    padding: 24px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
}

.results_error_title {
    color: #191717;
    font-family: Nohemi_Regular;
    font-size: 20px;
    line-height: 120%;
    margin: 0;
}

.results_error_text {
    color: #403C3A;
    font-family: Geist_Regular;
    font-size: 18px;
    line-height: 134%;
    margin: 0px;
}

.results_done_screen {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    background-color: #27A577;
    padding: 0;
    height: auto;
    min-height: 100vh;
    justify-content: space-between;
}

.results_done_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
}

.results_done_title {
    color: #FBFBF9;
    text-align: center;
    font-family: Nohemi_Regular;
    font-size: 72px;
    line-height: 134%;
    width: 100%;
    margin: 0;
}

.results_done_paragraph {
    color: #F5F5EF;
    text-align: center;
    font-family: Geist_Regular;
    font-size: 24px;
    line-height: 134%;
    width: 100%;
    margin: 0;
    margin-top: 24px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 48px;
}

.title {
    color: #191717;
    font-family: Nohemi_Bold;
    font-size: 36px;
    line-height: 120%;
    margin: 0;
}

.description {
    color: #403C3A;
    text-align: center;
    font-family: Geist_Regular;
    font-size: 20px;
    line-height: 150%;
    margin: 0;
}

.top_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 56px;
}

.circle {
    position: relative;
    background-color: #6558f5;
    padding: 1rem;
    border-radius: 50%;
    width: 6rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "Geist_Regular";
    font-size: 1.5rem;
}

.results {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 32px;
    justify-content: space-between;
    height: auto;
    min-height: 88vh;
    margin-top: 48px;
}

.results_buttons {
    display: flex;
    gap: 24px;
}

.results_footer_icon {
    position: absolute;
    bottom: -4px;
    right: 128px;
}

.result_screen_export {
    display: flex;
    flex-direction: column;
    width: 1024px;
    height: auto;
}

.results_header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-self: flex-start;
    padding: 0px 128px;
    width: calc(100% - 256px);
}

.min_height_auto {
    min-height: auto;
}

.padding_h {
    padding: 0 24px !important;
}

.header_error {
    display: flex;
    padding: 32px !important;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 24px;
    background-color: #fff;
}

.results_header_size {
    width: 100% !important;
}

.results_info {
    max-width: 917px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 256px);
    align-items: center;
    gap: 20px;
    padding: 0px 128px;
}

.results_info_item {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: .5rem;
    justify-content: center;
}

.results_info_item_details {
    display: flex;
    flex-direction: column;
    width: 60%;
    gap: 0rem;
}

.results_info_item_battery {
    display: flex;
    align-items: flex-end;
    gap: 57px;
}

.item_info {
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    background-color: #58C322;
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    border-radius: 2rem;
    align-items: center;
    gap: .25rem;
}

.results_footer {
    width: calc(100% - 256px);
    background-color: #27A577;
    display: flex;
    flex-direction: column;
    padding: 150px 128px;
    position: relative;
}

.results_footer_container {
    max-width: 850px;
    display: flex;
    align-items: flex-end;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 100%;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    justify-content: space-between;
}

.results_footer_logo_info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    gap: 2rem;
}

.preFooter {
    margin-top: 50px;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.preFooterTitle {
    max-width: 500px;
    color: #191717;
    font-size: 24px;
    font-family: Nohemi_Bold;
    margin: 0;
}

.preFooterContent {
    display: flex;
    align-items: center;
    gap: 40px;
}

.preFooterDescription {
    color: #403C3A;
    font-size: 14px;
    font-family: Geist_Regular;
    margin: 0;
}

.w_60 {
    width: 70% !important;
    align-items: flex-start !important;
}

.button_container {
    background-color: #EFEEE1 !important;
    color: #191717 !important;
    font-size: 21px !important;
}

.paragraph {
    color: #403C3A;
    font-size: 21px;
    font-family: Geist_Regular;
    margin: 0;
}

.paragraph_small {
    color: #403C3A;
    font-size: 16px;
    font-family: Geist_Regular;
    margin: 0;
    border-radius: 12px;
    background: #F5F5EF;
    padding: 16px 12px;
    max-width: 288px;
    width: 100%;
}

.bottom_title {
    color: #191717;
    font-size: 57px;
    font-family: Nohemi_Bold;
    margin: 0;
    text-align: left;
}

.title_item {
    color: #403C3A;
    font-size: 28px;
    font-family: Nohemi_Regular;
    margin: 0;
}

.paragraphs_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.header_info {
    width: auto !important;
    display: flex;
    background-color: transparent;
    border-radius: 2rem;
    align-items: flex-start;
    gap: 14px;
}

.info_text {
    color: white;
    font-size: 16px;
    font-family: Geist_Regular;
    margin: 0;
}

.info_text_bottom {
    color: white;
    font-size: 12px;
    font-style: italic;
    font-family: Geist_Regular;
    margin: 0;
    color: #403C3A;
    text-align: left;
}

.table_title {
    text-align: left;
    color: #191717;
    font-size: 23px;
    font-family: Geist_Bold;
    margin: 0;
}

.table_title:last-child {
    padding-left: 15px;
}

.table_item {
    text-align: left;
    color: #403C3A;
    font-size: 23px;
    font-family: Geist_Regular;
    margin: 0;
}

.table_item:last-child {
    padding-left: 15px;
}

.howItWorks {
    padding: 0px 240px;
    margin-top: 3rem;
    min-height: 83vh;
}

.contentHowItWorks {
    display: flex;
    flex-direction: column;
    align-items: unset;
    gap: 32px;
}

.allContentContainer {
    margin-left: 240px;
    margin-top: 16px;
    position: relative;
    display: flex;
    height: auto;
    min-height: calc(100vh - 153px);
}

.allContent {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 0px;
    align-items: center;
    justify-content: space-between;
}

.imageContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    min-width: 500px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    margin: 0;
    padding-top: 20px;
}

/* Titles and Texts */
.titleIntro {
    text-align: left;
    font-family: Nohemi_Regular;
    font-size: 64px;
    margin: 0;
    color: #FBFBF9;
}

.textIntro {
    text-align: left;
    font-family: Geist_Regular;
    font-size: 24px;
    line-height: 32.16px;
    margin: 0;
    color: #F5F5EF;
}

.textContainerIntro {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.nextButtonIntro {
    min-width: 360px;
}

.footerErrorContainer {
    width: -webkit-fill-available;
    width: -moz-available;
    position: absolute;
    bottom: 0;
}

.no_padding {
    padding: 0;
}

.goBack {
    margin-bottom: 44px;
}

.results_info_detailed_item {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.results_info_detailed {
    display: flex;
    width: calc(100% - 256px);
    justify-content: space-between;
    gap: 24px;
    padding: 0px 128px;
}

.contentHowItWorksText {
    display: flex;
    gap: 68px;
}

.scannerFaceInfo {
    margin-top: 0px;
    width: 100%;
    max-width: 470px;
    object-fit: contain;
}

.textLeft {
    text-align: left;
    padding-left: 30px;
}

@media only screen and (min-width: 1900px) {
    .results_footer_container {
        max-width: unset;
    }
}

@media only screen and (max-width: 1350px) {
    .results_footer_container {
        max-width: 550px;
    }
}

@media only screen and (max-width: 1280px) {
    .results_info_detailed {
        flex-direction: column;
    }

    .contentHowItWorksText {
        flex-direction: column;
    }

    .scannerFaceInfo {
        max-width: unset;
    }

    .goBack {
        margin-bottom: 0px;
    }
}

@media only screen and (min-height: 1600px) {
    .top_container {
        gap: 20px;
    }

    .results {
        gap: 0px !important;
    }

    .results_footer {
        padding: 68px 128px;
    }

    .paragraphs_container {
        max-width: 382px;
        gap: 28px;
    }

    .header_info {
        margin-top: 72px;
    }

    .howItWorks {
        min-height: 88vh;
        padding: 0px 128px;
    }

    .allContentContainer {
        margin-left: 0px;
    }

    .imageContainer {
        min-width: unset;
        width: 90%;
        align-self: flex-end;
    }

    .allContent {
        display: flex;
        flex-direction: column;
        gap: 80px;
        align-items: center;
        padding-bottom: 110px;
    }

    .container {
        align-items: center;
        gap: 48px;
    }

    .titleIntro {
        text-align: center;
        font-size: 72px;
    }

    .textContainerIntro {
        gap: 16px;
    }
    
    .textIntro {
        text-align: center;
    }

    .nextButtonIntro {
        font-size: 22px;
    }
}

@media only screen and (max-width: 1000px) {
    .allContentContainer {
        min-height: unset;
        margin-left: 0px;
    }

    .paragraph_small {
        width: calc(100% - 32px);
        max-width: unset;
    }

    .imageContainer {
        min-width: unset;
        width: 60%;
        align-self: flex-end;
        margin-bottom: 0px;
    }

    .table_title {
        font-size: 20px;
    }

    .table_item {
        font-size: 18px;
    }

    .header_info {
        margin-top: 100px;
        width: 65% !important;
        margin-top: 32px;
    }

    .allContent {
        display: flex;
        flex-direction: column;
        gap: 38px;
        align-items: center;
        padding-bottom: 32px;
        margin-top: 60px;
        justify-content: flex-start;
    }

    .container {
        align-items: center;
        gap: 32px;
        padding: 0 16px;
    }

    .textContainerIntro {
        gap: 12px;
    }

    .titleIntro {
        text-align: center;
        font-size: 30px;
        line-height: 134%; /* 40.2px */
    }
    
    .textIntro {
        text-align: center;
        font-size: 18px;
        line-height: 134%; /* 24.12px */
    }

    .nextButtonIntro {
        font-size: 18px !important;
        min-width: unset;
        width: 100%;
    }

    .containerError {
        padding: 0px 24px;
        margin-top: 96px;
        min-height: 400px;
    }

    .preFooter {
        align-items: center;
    }

    .preFooterTitle {
        text-align: center;
    }

    .preFooterContent {
        align-items: center;
        gap: 24px;
        flex-direction: column;
    }

    .preFooterDescription {
        text-align: center;
    }

    .results_footer_container {
        flex-direction: column;
        align-items: center;
    }

    .error_container {
        padding: 0 1rem;
    }

    .results_buttons {
        flex-direction: column;
        gap: 14px;
        width: calc(100% - 32px);
        padding: 0 16px;
    }

    .goBack {
        margin-left: 0px;
    }

    .results {
        margin-top: 92px !important;
    }

    .top_container {
        padding: 0;
        gap: 20px;
    }

    .howItWorks {
        padding: 0px 32px;
        min-height: 100vh;
        margin-top: 92px
    }

    .w_60 {
        width: 100% !important;
        align-items: center;
    }

    .content {
        padding: 0px;
    }

    .button_container {
        margin-top: 16px;
        margin-bottom: 0px;
        font-size: 18px !important;
    }

    .results_header {
        padding: 0 16px;
        gap: 20px;
        width: calc(100% - 32px);
    }

    .title {
        font-size: 30px;
    }

    .bottom_title {
        font-size: 24px;
    }

    .results_done_title {
        font-size: 32px;
    }

    .results_done_paragraph {
        font-size: 16px;
    }

    .results_done_container {
        padding: 24px;
        padding-bottom: 70px;
    }

    .paragraph {
        font-size: 21px;
    }

    .results_info {
        padding: 0 16px;
        width: calc(100% - 32px);
    }

    .title_item {
        font-size: 24px;
    }

    .info_text {
        font-size: 16px;
    }

    .results_footer {
        padding: 32px 16px;
        width: calc(100% - 32px);
    }

    .results_info_item {
        flex-direction: column;
        gap: 20px;
    }

    .results_info_item_details {
        width: 100%;
    }

    .results_info_item_battery {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .results_footer_logo_info {
        width: 95%;
    }

    .results_footer_icon {
        right: 16px;
    }

    .info_text_bottom {
        font-size: 12px;
    }

    .results_info_detailed {
        padding: 0px 16px;
        width: calc(100% - 32px);
    }


}

@media only screen and (max-width: 1000px) {

}
