.allContainer {

 width: -webkit-fill-available;
 overflow: hidden;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
   
 
}

.sectionContainer {
    display: flex;
    padding: 48px 64px;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    margin: auto; 
    max-width: 1000px;
    width: 100%; 
    box-sizing: border-box; 
}

.headTitle {
    color: #191717;
    font-family: Nohemi_Regular;
    font-size: 36px;
    line-height: 75%; /* 75.04px */
    width: 100%;
    height: auto;
    gap: 24px;
    margin-top: 20px;
}

.content {
    display: flex;
    align-items: center;
    gap: 48px;
    align-self: stretch;
}

.proceed_header {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 12px;
    padding: 0 2rem;
    margin-bottom: 40px;
}

.contentEndSection {
    display: flex;
    padding: 24px 0px 32px 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    flex-grow: 1; /* Make the container grow to fill the remaining space */
    width: 100%; /* Ensure it stretches horizontally */
}

.paragraph {
    color: #403C3A;
    font-size: 16px;
    font-family: Geist_Regular;
    margin: 0;
}

.notificationHand {
    position: relative;
    right: -60px;
    z-index: 1;
    display: flex;
    align-self: flex-end;
}

.title {
    color: #191717;

    /* heading/heading-xl */
    font-family: Nohemi_Regular;
    font-size: 24px;
    line-height: 134%; /* 32.16px */
    margin-bottom: 6px;
}

.endSectionTitle {
    color: #FBFBF9;
    font-family: Nohemi_Regular;
    font-size: 48px;
    line-height: 134%; /* 64.32px */
    align-self: stretch;
    
}

.textEndContainer {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 336px; /* Fixed height for desktop */
    font-size: 18px;
    box-sizing: border-box; /* Include padding in calculations */
}

.endSectionText {
    color: #F1F1E9;
    font-feature-settings: 'liga' off, 'clig' off;
    /* body-xl/body-xl */
    font-family: Geist_Regular;
    font-size: 18px;
    line-height: 134%; /* 24.12px */
    align-self: stretch ;
}

.contentColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}

.text {
    color: #403C3A;
    font-feature-settings: 'liga' off, 'clig' off;
    /* body-md/body-md */
    font-family: Geist_Regular;
    font-size: 14px;
    line-height: 140%; /* 19.6px */
}

.endSection {
    background: #27A577;
    display: flex;
    padding: 48px 0px 75px 240px;
    align-items: center;
    gap: 24px;
    align-self: stretch; 
    width: calc(100% - 240px);
    position: relative;
    justify-content: space-between;
}

.image {
    height: 200px;
    flex: 1 0 0;
}

.imageDashboard {
    height: 200px;
    flex: 1 0 0;
    background-color: white;
    border-radius: 16px;
    
}
.slide_item {
    display: flex;
    width: 100%;
    padding: 36px 140.258px 0px 140.258px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: transparent;
    box-shadow: 0px 20px 24px -4px rgba(0, 0, 0, 0.08), 0px 8px 8px -4px rgba(0, 0, 0, 0.03);
}

.arrows {
    position: absolute;
    display: flex;
    gap: 15px;
    bottom: -42px;
    right: 15px;
}

.arrow {
    top: 0 !important;
    position: relative !important;
    background: transparent !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 48px !important;
    height: 48px !important;
    opacity: 1 !important;
    transform: none !important;
}

.arrow_left {
    left: 0 !important;
}

.arrow_right {
    transform: rotate(180deg) !important;
    right: 0 !important;
}

.pagination {
    bottom: -19px !important;
    left: 15px !important;
    justify-content: flex-start !important;
    padding: 0 !important;
    gap: 8px !important;
}

.item_image {
    max-width: 100%;
    max-height: 450px;
}

.item_image_bottom {
    margin-bottom: 36px;
}

.goBack {
    display: flex;
    align-self: flex-start;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1439px) {
    .endSection {
        padding: 48px 0px 75px 120px;
        width: calc(100% - 120px);
    }
}

@media only screen and (max-width: 1205px) {
    .endSection {
        padding: 48px 0px 75px 64px;
        width: calc(100% - 64px);
    }
}

@media only screen and (max-width: 1000px) {
    .sectionContainer {
        padding: 24px 16px;
        margin-top: 67px;
        gap: 20px;
    }

    .container {
        padding: 0px;
    }

    .proceed_header {
        padding: 0;
        margin-bottom: 16px;
        gap: 8px;
    }

    .image {
        width: 100%;
    }

    .imageDashboard {
        width: 100%;
    }

    .text {
        margin: 0px;
    }

    .content {
        flex-direction: column;
        gap: 24px;
    }

    .reverse {
        flex-direction: column-reverse;
    }

    .textEndContainer {
        padding: 24px 16px;
        height: auto;
        margin-top: 100px;
    }

    .endSection {
        display: flex;
        padding: 24px 0px 24px 0px;
        width: 100%;
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 32px;
        align-self: stretch;
        height: auto;
    }

   .notificationHand {
        right: -57.049px;
        top: -175px;
        width: 205.996px;
        height: 205.636px;
        position: absolute;
    }

    .endSectionTitle {
        font-size: 24px;
    }

    .paragraph {
        font-size: 18px;
    }

    .endSectionText {
        font-size: 16px;

    }

    .headTitle {
        font-size: 32px;
        margin: 0px;
        margin-top: 12px;
        line-height: 135%;
    }
    
    .title {
        font-size: 24px;
        margin: 0px;
    }

    .slide_item {
        padding: 16px 18px 15.075px 18px;
        border-radius: 12px;
    }

    .item_image_bottom {
        margin-bottom: 0px;
    }
}

@media only screen and (min-height: 1600px) {
    .allContainer {
        width: -webkit-fill-available;
        overflow: hidden;
    }
   
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
   
    .sectionContainer {
        display: flex;
        padding: 48px 128px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
    }

    .headTitle {
        color: #191717;
        font-family: Nohemi_Regular;
        width: 100%;
        height: auto;
        gap: 24px;
        margin-top: 20px;
    }
   
    .content {
        display: flex;
        align-items: center;
        gap: 48px;
        align-self: stretch;
    }

    .proceed_header {
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        gap: 12px;
        padding: 0 2rem;
        margin-bottom: 40px;
    }
   
    .contentEndSection {
        display: flex;
        padding: 24px 0px 32px 0px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;
        flex-grow: 1; /* Make the container grow to fill the remaining space */
        width: 100%; /* Ensure it stretches horizontally */
    }

    .paragraph {
        color: #403C3A;
        font-size: 16px;
        font-family: Geist_Regular;
        margin: 0;
    }
   
    .notificationHand {
        position: absolute;
        right: 0;
        z-index: 1;
    }
   
    .title {
        color: #191717;
        /* heading/heading-xl */
        font-family: Nohemi_Regular;
        font-size: 24px;
        line-height: 134%; /* 32.16px */
        margin-bottom: 6px;
    }
   
    .endSectionTitle {
        color: #FBFBF9;
        font-family: Nohemi_Regular;
        font-size: 48px;
        line-height: 134%; /* 64.32px */
        align-self: stretch;
    }
   
    .textEndContainer {
        max-width: 478px !important; /* Shrink max-width */
        height: auto; /* Allow height to adapt */
        display: flex;
        padding: 24px 0px 32px 0px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
    }

    .endSectionText {
        color: #F1F1E9;
        font-feature-settings: 'liga' off, 'clig' off;
        /* body-xl/body-xl */
        font-family: Geist_Regular;
        font-size: 18px;
        line-height: 134%; /* 24.12px */
        align-self: stretch;
    }
   
    .contentColumn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;
    }
   
    .text {
        color: #403C3A;
        font-feature-settings: 'liga' off, 'clig' off;
        /* body-md/body-md */
        font-family: Geist_Regular;
        font-size: 14px;
        line-height: 140%; /* 19.6px */
    }
   
    .endSection {
        background: #27A577;
        display: flex;
        padding: 48px 0px 75px 128px;
        align-items: center;
        gap: 24px;
        flex-grow: 1 0 0;
        align-self: stretch; 
        width: 100%;
        position: relative;
    }
   
    .image {
        height: 200px;
        flex: 1 0 0;
    }
   
    .slide_item {
        display: flex;
        width: 100%;
        padding: 36px 140.258px 0px 140.258px;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        background: transparent;
        box-shadow: 0px 20px 24px -4px rgba(0, 0, 0, 0.08), 0px 8px 8px -4px rgba(0, 0, 0, 0.03);
    }
   
    .arrows {
        position: absolute;
        display: flex;
        gap: 15px;
        bottom: -42px;
        right: 15px;
    }
   
    .arrow {
        top: 0 !important;
        position: relative !important;
        background: transparent !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        width: 48px !important;
        height: 48px !important;
        opacity: 1 !important;
        transform: none !important;
    }
   
    .arrow_left {
        left: 0 !important;
    }
    
    .arrow_right {
        transform: rotate(180deg) !important;
        right: 0 !important;
    }
    
    .pagination {
        bottom: -19px !important;
        left: 15px !important;
        justify-content: flex-start !important;
        padding: 0 !important;
        gap: 8px !important;
    }
    
    .item_image {
        max-width: 100%;
        max-height: 450px;
    }
    
    .item_image_bottom {
        margin-bottom: 36px;
    }
    
    .goBack {
        display: flex;
        align-self: flex-start;
        justify-content: center;
        align-items: center;
    }
}
