.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    font-family: Geist_Regular;
    font-size: 21px;
    color: #403C3A;
    margin: 0;
    line-height: 24px;
}

.custom_radio {
    height: 44px;
    display: flex;
    padding: 12px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    margin: 0;
    background-color: #F1F1E9;
    color: #191717;
    font-family: Geist_Bold !important;
}

.custom_radio input {
    display: none; /* Hide the default radio button */
}

.custom_icon {
    border-radius: 50%;
    background: #FDFDFD;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom_icon_unchecked  {
    border-radius: 50%;
    background: #FDFDFD;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ACAB9A;
}

@media screen and (max-width: 1000px) {
    .title {
        font-size: 19px;
    }
}
