@font-face {
    font-family: Geist_Regular;
    src: url('../assets/fonts/Geist/Geist-Regular.ttf');
}

@font-face {
    font-family: Geist_Bold;
    src: url('../assets/fonts/Geist/Geist-Bold.ttf');
}

@font-face {
    font-family: Nohemi_Regular;
    src: url('../assets/fonts/Nohemi/Nohemi-Regular.ttf');
}

@font-face {
    font-family: Nohemi_Bold;
    src: url('../assets/fonts/Nohemi/Nohemi-Bold.ttf');
}

body {
    margin: 0 auto;
}

html, #root {
    background-color: #FBFBF9;
}

.font-syne-bold {
    font-family: Nohemi_Bold !important;
}

.font-syne-regular {
    font-family: Nohemi_Regular !important;
}

.font-inter-bold {
    font-family: Geist_Bold !important;
}

.font-inter-regular {
    font-family: Geist_Regular !important;
}

input {
    font-family: Geist_Regular !important;
}

legend {
    font-family: Geist_Regular !important;
}

label {
    font-family: Geist_Regular !important;
}

strong  {
    font-family: Geist_Bold !important;
}

.fs-italic {
    font-style: italic;
}

.text-center {
    text-align: center;
}

.d-flex {
    display: flex;
}

.btn {
    cursor: pointer;
    padding: 16px 24px;
    background-color: #27A577;
    color: #FBFBF9;
    font-family: Geist_Regular;
    font-size: 16px;
    border: none;
    box-shadow: none;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.btn-big {
    cursor: pointer;
    padding: 24px 48px;
    background-color: #27A577;
    color: #FBFBF9;
    font-family: Geist_Regular;
    font-size: 27px;
    border: none;
    box-shadow: none;
    border-radius: 81px;
    width: fit-content;
}

.btn-secondary {
    cursor: pointer;
    padding: 16px 24px;
    background-color: #EFEEE1;
    color: #191717;
    font-family: Geist_Regular;
    font-size: 16px;
    box-shadow: none;
    border-radius: 40px;
    width: fit-content;
}

.opacity-50 {
    opacity: 0.5;
}

.green-text-details {
    color: #26A749;
}

.handsStyle {
    min-width: 683px;
}

@media only screen and (max-width: 1250px) {
    .handsStyle {
        min-width: 400px;
    }
}

@media only screen and (min-height: 1600px) {
    .handsStyle {
        min-width: 1000px;
    }
}

@media only screen and (max-width: 1000px) {
    .btn-big {
        cursor: pointer;
        padding: 16px 24px;
        background-color: #27A577;
        color: #FBFBF9;
        font-family: Geist_Regular;
        font-size: 16px;
        border: none;
        box-shadow: none;
        border-radius: 40px;
        width: 100%;
    }

    .btn-secondary {
        width: 100%;
    }

    .handsStyle {
        width: 100%;
    }
}
