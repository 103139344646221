input {
    border: none;
}

input:focus-visible {
    outline: none;
}

input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
}

svg {
    cursor: pointer;
}

.container { 
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
}

.label {
    font-family: Geist_Regular;
    font-size: 21px;
    color: #403C3A;
    margin: 0;
    line-height: 24px;
}

.labelError {
    font-family: Geist_Regular;
    font-size: 14px;
    color: #D41111;
    margin: 0;
    line-height: 24px;
}

.input {
    font-family: Geist_Regular;
    color: #403C3A;
    padding: 16px 20px;
    font-size: 1rem;
    border-radius: 6px;
    background-color: #F1F1E9;
}

.arrows {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 6px;
    top: 45px;
    right: 1rem;
}

@media screen and (max-width: 1000px) {
    .label {
        font-size: 19px;
    }
}
