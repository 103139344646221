/* General Styles */
.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    margin: 0;
    padding-top: 20px;
}

.allContentContainer {
    margin-left: 240px;
    margin-top: 16px;
    position: relative;
    height: auto;
    min-height: calc(100vh - 153px);
}

.allContent {
    display: flex;
    flex-direction: row-reverse;
    gap: 0px;
    align-items: center;
    justify-content: space-between;
}

.imageContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}

/* Step 2 Specific Image Styling */

/* Step Styles */
.step {
    position: relative;
    flex-grow: 1;
    overflow: hidden;
}

.step--1 {
    background-color: #EFECFF;
}

.step--2 {
    background-color: #FFEB99;
}

.step--3 {
    background-color: #C7E2FB;
}

.step--4 {
    background-color: #BBEDD2;
}

/* Titles and Texts */
.title {
    text-align: left;
    font-family: Nohemi_Regular;
    font-size: 65px;
    margin: 0;
}

.text {
    text-align: left;
    font-family: Geist_Regular;
    font-size: 24px;
    line-height: 32.16px;
    margin: 0;
}

.nextButton {
    min-width: 360px;
}

/* Specific Colors for Each Step */
.title1 { color: #340B72; }
.text1 { color: #6D29E2; }

.title2 { color: #3D3500; }
.text2 { color: #4F4400; }

.title3 { color: #003A5A; }
.text3 { color: #0A5074; }

.title4 { color: #083D2A; }
.text4 { color: #0C5138; }

/* Header Styles */
.headerContainer {
    /* width: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 55px; */
    position: absolute;
}

.goBack {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

@media only screen and (max-width: 1350px) {
    .allContentContainer {
        margin-left: 64px;
    }
}

/* Design for mobile/small tablet - start */
@media only screen and (max-width: 1000px) {
    .container {
        padding: 0 16px;
        bottom: 12px;
        gap: 32px; /*initial 32px*/
        position: relative;
    }

    .allContentContainer {
        margin-top: 77px;
        margin-left: 0px;
        min-height: calc(100vh - 143px);
    }

    .allContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        display: flex;
        padding: 32px 0px;
        gap: 38px;
        flex-shrink: 0;
    }

    .imageContainer {
        display: flex;
        align-self: flex-end;
    }

    .title {
        width: 100%;
        font-size: 30px;
        line-height: 134%;
        text-align: center;
    }

    .text {
        width: 100%;
        text-align: center;
        font-size: 18px;
    }

    .nextButton {
        min-width: unset;
    }

    .headerContainer {
        margin-left: 16px;
    }

    .step1Image {
        display: flex;
        height: 300px;
        width: 300px;
        padding-left: 133px;
        justify-content: flex-end;
        align-items: center;
        flex-shrink: 0;
    }

    .step2ImageContainer {
        align-self: center;
    }

    .step2Image {
        display: flex;
        padding: 0px 72px 17px 72px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 249px;
        height: 218px;
    }

    .step3ImageContainer {
        align-self: center;
        width: 100%;
    }

    .step3Image {
        display: flex;
        align-items: center;
        width: 500px;
        height: 389px;
        padding-left: 0;
    }

    .step4ImageContainer {
        align-self: center;
        width: 100%;
    }

    .step4Image {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        flex-shrink: 0;
    }
}
/* Design for mobile/small tablet - end */

/* Design for mobile/small tablet with specific rules for media resolution - start */
@media only screen and (max-width: 750px) {
    .allContentContainer {
        min-height: calc(100vh - 173px);
    }
}

@media only screen and (max-width: 390px) {
    .allContentContainer {
        min-height: calc(100vh - 100px);
    }
}
/* Design for mobile/small tablet with specific rules for media resolution - end */
 
/* Design for screens with big height (yoboo tablet) - start */
@media only screen and (min-height: 1600px) {
    .allContentContainer {
        height: calc(100% - 106px);
    }

    .allContent {
        display: flex;
        flex-direction: column;
        gap: 80px;
        align-items: center;
    }

    .imageContainer {
        align-self: flex-end;
        margin-top: 80px;
        margin-bottom: 0px;
    }

    .step2Image {
        width: 708px;
    }

    .step2ImageContainer {
        align-self: center;
        margin-left: -64px;
        margin-top: 200px;
    }

    .step3ImageContainer {
        align-self: center;
        margin-left: -35px;
    }

    .step4ImageContainer {
        align-self: center;
        margin-left: -64px;
    }

    .customHandsStyle {
        min-width: 1050px;
    }

    .container {
        position: absolute;
        align-items: center;
        width: 100%;
        max-width: 768px;
        margin: 0 auto;
        bottom: 50px;
        margin-left: -64px;
    }

    .nextButton {
        min-width: unset;
        margin: 0 auto;
    }

    .title {
        text-align: center;
    }

    .text {
        text-align: center;
    }
}
/* Design for screens with big height (yoboo tablet) - end */

/* Design for small phones - start */
@media only screen and (max-width: 400px) {
    .imageContainer {
        align-self: unset;
    }

    .step1Image {
        height: 200px;
        width: 100%;
    }
}
/* Design for small phones - start */
