.container {
    padding: 0px;
    overflow: hidden;
}

.footerContainer {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.buttonOverlayContainer {
    display: none;
    position: fixed;
    bottom: 0px;
    height: 130px;
    width: calc(100% - 32px);
    padding: 0px 16px;
    background: linear-gradient(0deg, #FBFBF9 66%, rgba(251, 251, 249, 0.00) 100%);
}

.buttonOverlay {
    position: absolute;
    bottom: 32px;
    width: calc(100% - 32px);
}

.content {
    margin: 0 auto;
    margin-top: 16px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    max-width: 960px;
    padding: 0px 240px;
    gap: 32px;
}

.slideInFromLeft {
    animation: slideInFromLeft 0.3s forwards;
}

.slideInFromRight {
    animation: slideInFromRight 0.3s forwards;
}

.slideOutToLeft {
    animation: slideOutToLeft 0.3s forwards;
}

.slideOutToRight {
    animation: slideOutToRight 0.3s forwards;
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOutToLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes slideOutToRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

.buttonWidth {
    max-width: 640px;
    margin: 0 auto;
    width: 100%;
}

.stepContainer {
    min-height: calc(100vh - 267px);
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.customHeight {
    margin-top: 100px !important;
    padding-bottom: 88px !important;
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-bottom: 88px;
    width: 100%;
}

.contentHow {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    max-width: 960px;
    padding: 6px 240px;
    margin-top: 50px !important;
}

.contentHowContainer {
    display: flex;
    gap: 68px;
}

.noMargin {
    margin: 0;
}

.scannerFace {
    margin-top: 0px;
    width: 100%;
    max-width: 470px;
}

.scannerFaceInfo {
    margin-top: 0px;
    width: 100%;
    max-width: 470px;
    object-fit: contain;
}

.title {
    color: #191717;
    font-family: Nohemi_Bold;
    line-height: 134%;
    margin: 0;
    width: 100%;
    margin-top: 32px;
    font-size: 36px;
}

.titleInfo {
    color: var(--Parch-Parch-16-Black, #191717);
    font-family: Nohemi_Regular;
    font-size: 56px;
    line-height: 134%; /* 75.04px */
    margin-top: 0px;
    margin-bottom: 8px;
}

.textInfo {
    color:  #403C3A;
    font-family: Geist_Regular;
    font-size: 18px;
    line-height: 134%;
    max-width: 640px;
    width: 100%;
}

.titleCookie {
    color: #191717;
    text-align: left;
    font-family: Nohemi_Bold;
    font-size: 36px;
    line-height: 134%;
    margin: 0;
}

.smallTitleCookie {
    color: #191717;
    text-align: left;
    font-family: Nohemi_Bold;
    font-size: 30px;
    line-height: 134%;
    margin: 20px 0px 0px 0px;
}

.description {
    color: #403C3A;
    text-align: center;
    font-family: Geist_Regular;
    font-size: 20px;
    line-height: 150%;
    margin: 0;
}

.descriptionCookie {
    color: #403C3A;
    text-align: left;
    font-family: Geist_Regular;
    font-size: 21px;
    line-height: 150%;
    margin: 0;
}

.label {
    font-family: Geist_Regular;
    font-size: 21px;
    color: #403C3A;
    margin: 0;
    line-height: 24px;
}

.linkCookie {
    color: #403C3A;
    text-align: left;
    font-family: Geist_Regular;
    font-size: 21px;
    line-height: 150%;
    margin: 0;
}

.listCookie {
    margin: 0;
    padding-left: 15px;
}

.headerContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    min-height: 48px;
}

.goBack {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.progressContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.progressText {
    color: #403C3A;
    font-family: Geist_Regular;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
}

.progressBarContainer {
    height: 12px;
    border-radius: 4px;
    background: #F1F1E9;
    width: 100%;
}

.progressBar {
    height: 12px;
    border-radius: 4px;
    background: #27A577;
}

.formContainer {
    width: 100%;
    max-width: 640px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    margin: 0 auto;
}

.info {
    color: #7F7B72;
    font-family: Geist_Regular;
    font-size: 21px;
    line-height: 134%;
    margin: 0;
    margin-top: 10px;
}

.itemsContainer {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-self: flex-start;
}

.item {
    color: #403C3A;
    font-family: Geist_Regular;
    font-size: 16px;
    line-height: 200%;
}

.checkboxItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.cookieContainer {
    width: 100%;
}

.checkboxContainer {
    align-self: flex-start;
    display: flex;
    padding: 8px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #F1F1E9;
    margin: 0;
    width: calc(100% - 48px);
}

.checkboxLabel {
    color: #403C3A;
    font-family: Geist_Regular;
    font-size: 18px;
    line-height: 24px;
}

.error {
    display: flex;
    align-self: flex-start;
    margin: 0;
    margin-top: -20px;
    color: #D41111;
    font-family: Geist_Regular;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    gap: 10px;
}

.custom_icon_checkbox {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FDFDFD;
}

.custom_icon_checkbox_unchecked {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 2px solid #ACAB9A;
    background: #FDFDFD;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 1px solid #445188;
    display: none;
}

.inputRules {
    color: #403C3A;
    font-family: Geist_Regular;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

.topLayout {
    background-color: #FBFBF9;
    padding: 16px 240px;
    width: calc(100% - 480px);
    align-self: center;
}

@media only screen and (max-height: 885px) and (min-width: 1000px) {
    .container {
        position: relative;
    }
}

@media only screen and (max-width: 1150px) {
    .customHeight {
        margin-top: 50px !important;
    }
}

@media only screen and (max-width: 1439px) {
    .content {
        padding: 0px 120px;
        width: calc(100% - 240px);
    }

    .contentHow {
        padding: 6px 120px;
        width: calc(100% - 240px);
    }

    .topLayout {
        padding: 16px 120px;
        width: calc(100% - 240px);
    }
}

@media only screen and (min-height: 1600px) {
    .textContainer {
        gap: 114px;
        margin-bottom: 0px;
    }

    .stepContainer {
        min-height: unset;
        position: static;
    }

    .title {
        font-size: 42px;
        margin-top: 38px;
    }

    .progressText {
        font-size: 16px;
    }

    .content {
        margin-top: 114px;
        flex-direction: column;
    }

    .contentHow {
        margin-top: -24px !important;
    }

    .contentHowContainer {
        margin-top: -40px;
        flex-direction: column;
        gap: 32px;
    }

    .buttons {
        margin-top: -18px;
    }

    .buttonWidth {
        font-size: 26px !important;
    }

    .formContainer {
        gap: 57px;
    }

    .error {
        margin-top: -50px;
    }

    .cookieContainer {
        margin-top: -90px;
        padding-bottom: 70px;
    }

    .scannerFace {
        margin-top: 46px;
        width: 100%;
        max-width: 640px;
    }

    .scannerFaceInfo {
        margin-top: 0px;
        width: 100%;
        max-width: unset;
    }

    .textInfo {
        width: 100%;
    }
}

@media only screen and (max-width: 1000px) {
    .linkCookie {
        font-size: 16px;
    }

    .buttonOverlayContainer {
        display: block;
    }

    .marginTopMobile {
        margin-top: -120px;
    }

    .container {
        min-height: unset;
        padding: 0px;
        margin-top: 215px;
    }

    .buttonWidth {
        max-width: unset;
        width: 100%;
    }

    .checkboxLabel {
        font-size: 16px;
    }

    .cookieContainer {
        margin-top: 24px;
    }

    .topLayout {
        padding: 16px;
        width: calc(100% - 32px);
    }

    .footerContainer {
        position: relative;
    }

    .titleInfo {
        margin-top: 12px;
    }

    .content {
        width: calc(100% - 32px);
        padding: 16px;
        margin: 0px;
        flex-direction: column-reverse;
    }

    .customHeight {
        margin-top: 65px !important;
        padding-bottom: 0px !important;
    }

    .contentHow {
        padding: 16px;
        align-items: center;
        margin-top: 10px !important;
        width: calc(100% - 32px);
        gap: 8px;
    }

    .contentHowContainer {
        gap: 32px;
        flex-direction: column;
    }

    .textContainer {
        gap: 32px;
        margin-top: 32px;
        margin-bottom: 0px;
    }

    .title {
        font-size: 24px;
        margin-top: 16px;
    }

    .description {
        font-size: 16px;
    }

    .info {
        font-size: 20px;
    }

    .formContainer {
        gap: 32px;
        max-width: unset;
    }

    .goBack {
        padding: 0px;
    }

    .progressContainer {
        gap: 16px;
    }

    .headerContainer {
        gap: 16px;
    }

    .error {
        margin-top: -28px;
    }

    .titleCookie {
        font-size: 24px;
    }

    .smallTitleCookie {
        font-size: 20px;
    }

    .descriptionCookie {
        font-size: 16px;
    }

    .scannerFace {
        margin-top: 16px;
        width: 100%;
    }

    .scannerFaceInfo {
        width: 100%;
        align-self: center;
        height: auto;
    }

    .titleInfo {
        font-size: 32px;
    }
}
